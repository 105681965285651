import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'


const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Layout = props => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={data => (
        <div className='antialiased layout'>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main role='main' id='main' className='flex flex-col flex-1'>{props.children}</main>
          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
