import React, { PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
// Components
import CheckLink from './checkLink'
import Social from './social'

const footerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    prismicSocial {
      ...PrismicSocialFragment
    }
    logo: file(relativePath: { eq: "logo-inverse.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

class Footer extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={`${footerQuery}`}
        render={data => {
          const footerData = data.prismicNavigation.data
          const socialData = data.prismicSocial.data
          return (
            <footer role='contentinfo' className='mt-auto bg-gray-800 text-gray-300'>
              <div className='mx-auto max-w-screen-xl px-4 md:px-8 py-10 grid gap-10 grid-cols-1 md:grid-cols-4'>
                <div className='space-y-4'>
                  <div className='uppercase font-semibold text-lg text-coolGray-400'>{footerData.company_display_name}</div>
                  <div dangerouslySetInnerHTML={{__html:footerData.company_address.html}} />
                  <div>
                    <a className='flex items-center text-lg font-medium hover:text-red-500 mb-2' href={`tel:${footerData.company_phone}`} target='_blank' rel='noopener noreferrer'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                      </svg> {footerData.company_phone}
                    </a>
                    <a className='flex items-center text-lg font-medium hover:text-red-500' href='/contact'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg> Contact Us
                    </a>
                  </div>
                </div>
                {footerData.body.map((footer, index) => {
                  return (
                    <div key={`footer-${index}`}>
                      <ul className='space-y-4'>
                      <li className='uppercase font-semibold text-lg text-coolGray-400' dangerouslySetInnerHTML={{__html:footer.primary.title}} />
                      {footer.items.map((footerItem, index) => {
                        return (
                          <li key={`footerItem-${index}`}>
                            <CheckLink className='py-2 text-white hover:text-red-500' link={footerItem.link}>{footerItem.link_label}</CheckLink>
                          </li>
                        )
                      })}
                      </ul>
                    </div>
                  )
                })}
                <div className='flex flex-col space-y-6 items-center md:items-end'>
                  <Img className='justify-center md:justify-end' fixed={data.logo.childImageSharp.fixed} alt={`${footerData.company_display_name} logo`} />
                  <Social networks={socialData} />
                </div>
              </div>
              <div className='text-center py-4 bg-gray-900 text-gray-300'>Copyright &copy; {footerData.company_display_name} <span>1977 - {new Date().getFullYear()}</span></div>
            </footer>
          )
        }}
      />
    )
  }
}

export default Footer
