import React, { PureComponent } from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Hamburger from 'hamburger-react'
import Img from 'gatsby-image'
import CheckLink from '../components/checkLink'
import Dropdown from '../components/dropdown'

const headerQuery = graphql`
{
  prismicNavigation {
    ...PrismicNavigationFragment
  }
  logo: file(relativePath: { eq: "logo-inverse.png" }) {
    childImageSharp {
      fixed(width: 240) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
}
`;

class Header extends PureComponent {

  constructor() {
    super()
    this.state = {
      mainNavExpanded: false,
    }
    this.toggleMainNav = this.toggleMainNav.bind(this);
  }
  toggleMainNav() {
    this.setState({
      mainNavExpanded:!this.state.mainNavExpanded
    })
  }
  render() {
    return (
      <StaticQuery
        query={`${headerQuery}`}
        render={data => {
          const headerData = data.prismicNavigation.data
          return (
            <header role='banner' className='z-20 top-0 left-0 right-0 absolute text-2xl lg:text-lg font-semibold'>
              <nav id='navigation' className='mx-auto relative flex items-center justify-between lg:justify-start p-4 md:px-8 max-w-screen-xl'>
                <Link to='/' className='mr-6 relative z-20 focus:outline-none focus:ring focus:ring-coolGray-500'>
                  <Img className='w-auto' fixed={data.logo.childImageSharp.fixed} alt={`${headerData.company_display_name} logo`} />
                </Link>
                <span className='lg:hidden z-20'>
                  <Hamburger
                    color='white'
                    label='Show menu'
                    rounded
                    easing='ease-in'
                    toggled={this.state.mainNavExpanded}
                    toggle={this.toggleMainNav }
                  />
                </span>
                <ul className={`${this.state.mainNavExpanded === false ? ('hidden'):('absolute inset-0 bg-gray-800 flex flex-col h-screen justify-center lg:justify-start pt-20 lg:pt-24 z-10')} lg:flex lg:items-center`}>
                  {headerData.nav.map((nav, index) => {
                    return (
                      <React.Fragment key={`nav-${index}`}>
                        {nav.items.length > 1 ? (
                          <Dropdown key={`nav-${index}`} items={nav.items}>{nav.primary.label}</Dropdown>
                        ):(
                          <li key={`nav-${index}`} className='m-2 text-black'>
                            <CheckLink className='block py-2 px-5 text-center text-gray-100 hover:text-white focus:text-coolGray-100 transition duration-150 ease-in-out focus:outline-none focus:ring focus:ring-coolGray-500' activeClassName='text-white bg-warmGray-800 rounded-lg' link={nav.primary.link} onClick={ this.toggleNav }>{nav.primary.label}</CheckLink>
                          </li>
                        )}
                      </React.Fragment>
                    )
                  })}
                </ul>
              </nav>
            </header>
          )
        }}
      />
    )
  }
}

export default Header
