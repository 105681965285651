import React, { PureComponent } from 'react'
import CheckLink from '../components/checkLink'

class Dropdown extends PureComponent {
  constructor() {
    super()
    this.state = {
      subNavExpanded: false,
    }
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  container = React.createRef();
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = event => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        subNavExpandedId:false
      });
    }
  };

  render() {
    return (
      <li ref={this.container} className='m-2 text-black'>
        <button className='block py-2 px-5 mx-auto text-center font-semibold text-gray-100 hover:text-white focus:text-coolGray-100 focus:outline-none transition duration-150 ease-in-out flex justify-center' aria-haspopup='true' aria-expanded={this.state.subNavExpandedId === this.props.children ? 'true':'false'} onClick={() => this.toggleSubNav(`${this.props.children}`)} onKeyDown={() => this.toggleSubNav(`${this.props.children}`)}>
          {this.props.children}
          <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-5 md:ml-auto' height='1em' width='1em'>
            <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor'/>
            <path d='M0 0h24v24H0z' fill='none'/>
          </svg>
        </button>
        <ul className={`${this.state.subNavExpandedId === this.props.children ? 'block lg:absolute lg:px-3 lg:py-1 lg:shadow-md lg:rounded flex flex-col divide-y divide-coolGray-600 lg:divide-coolGray-200 bg-gray-900 lg:bg-white':'hidden'}`}>
          {this.props.items.map((subNav, index) => {
            return (
              <li className='' key={`subNav-${index}`}>
                <CheckLink className='block py-2 mx-2 text-center text-white lg:text-gray-900 hover:text-red-500 focus:text-red-400 transition duration-150 ease-in-out focus:outline-none focus:ring focus:ring-coolGray-500' link={subNav.subnav_link}>{subNav.subnav_link_label}</CheckLink>
              </li>
            )
          })}
        </ul>
      </li>
    )
  }
}

export default Dropdown
